import React from 'react';

const PurchaseButton = () => (
  <div>
    <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
      <input type="hidden" name="cmd" value="_s-xclick" />
      <input type="hidden" name="hosted_button_id" value="CHJDX9YJL5YDG" />
      <input type="hidden" name="on0" value="All Weekend Ticket" />
      <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_buynowCC_LG.gif" border="0" name="submit" alt="PayPal - The safer, easier way to pay online!" />
      <img alt="" border="0" src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif" width="1" height="1" />
    </form>
    <h4 css={{
      fontSize: '1.25em',
      fontWeight: 400,
      margin: '15px 0 5px 0',
    }}
    >
      30$ for all weekend
    </h4>
    <p>Tickets also sold in store at Critical Hit Games</p>
  </div>
);

export default PurchaseButton;

/* eslint-disable max-len */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';
import Layout from '../components/layout';
import SEO from '../components/seo';
import PurchasePlea from '../components/purchasePlea';
import PurchaseButton from '../components/purchaseButton';
import TicketImage from '../components/ticketImage';
import ContentPane from '../components/contentPane';

const StyledList = styled.ul`
`;

const StyledListItem = styled.li`
  margin: 1px 0px;
`;

const StyledSubList = styled.ul`
  margin: 0 0 0 0;
  padding: 0 0 15px 0;
  list-style-type: none;
`;
const StyledSubListItem = styled.li`
  margin: 0;
`;

const StyledDayHeader = styled.h3`
  margin: 30px 0 5px 0;
  margin-bottom: 0;
  padding: 0; 
`;

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <div id="topPurchase">
      <PurchasePlea />
    </div>
    <ContentPane title="What each ticket gets" dark>
      <div css={{
        display: 'flex',
        justifyContent: 'space-between',
      }}
      >
        <StyledList css={{
          order: 1,
        }}
        >
          <StyledListItem>
            Gaming all weekend for 1 person
            <StyledSubList>
              <StyledSubListItem>Friday 7pm - 12pm</StyledSubListItem>
              <StyledSubListItem>Saturday 12pm - 3am</StyledSubListItem>
              <StyledSubListItem>Sunday 12pm - 12am</StyledSubListItem>
            </StyledSubList>
          </StyledListItem>
          <StyledListItem>Raffle tickets for free games</StyledListItem>
          <StyledListItem>Provides 3-4 boardgames for underserved kids</StyledListItem>
        </StyledList>
        <div css={{
          width: '208px', order: 2,
        }}
        >
          <TicketImage />
        </div>
      </div>
    </ContentPane>

    <ContentPane title="The Event" anchor="description">
      <p>
CritAid is a full weekend of board gaming for a cause from January 24th through the 26th.
All proceeds from the event will go towards purchasing card and board games for underserved children getting check-ups at a Tampa pediatric clinic, so like a lollipop but way more awesome. Game for the entire time knowing that you’re promoting health and helping spread your hobby to the next generation of gamers.

      </p>
      <p>Admission tickets will be sold in person at Critical Hit Games or here in a very limited quantity.  Buy yours early!  Tickets are just $30 for the entire weekend.  Admission includes 2 raffle tickets. More raffle tickets will be sold at the event for $5 each.  Prizes include board games donated by Critical Hit Games and it’s patrons.  A silent auction will contain donations from local businesses, new or like new copies of board games and mystery boxes full of only the best stuff.</p>
    </ContentPane>

    <ContentPane title="Agenda" anchor="agenda" dark>
      <StyledDayHeader>Friday</StyledDayHeader>
      <p>
        Gaming starts at
        {' '}
        <a href="https://www.brewerstastingroom.com/#!location/c2q4">The Brewers Tasting Room</a>

        {' '}
        on Friday, January 24th at 7PM until midnight.
        {' '}
Bring your best social games or borrow some from the Critical Hit library for the night.  Knock back a few while your co-conspirators loudly call you “Werewolf”, “Spy” or “Hitler” in a public place.  If you insist on keeping it classy, reach for a pint and crack open a solid Euro.
      </p>

      <StyledDayHeader>Saturday</StyledDayHeader>
      <p>
Saturday, January 25th is the start of the long-haul gaming.
        {' '}
        <a href="http://criticalhitgames.biz">Critical Hit Games</a>
        {' '}
will  be hosting the day starting at noon until 3AM the next day.
        {' '}
The store will open up their private rooms for free to all attendees.  The day will be where you can break out your biggest, most epic games.  Raffle tickets will be called throughout the day.  The silent auction will be open for your perusal and bidding.
      </p>

      <StyledDayHeader>Sunday</StyledDayHeader>
      <p>
Sunday, January 26th is yet another day of gaming.
        {' '}
        <a href="http://criticalhitgames.biz/">Critical Hit Games</a>
        {' '}
again will be hosting starting at noon until midnight.
        {' '}
Finish up those epic games from the previous day or get as many games as you can to the table.  Put that shelf of shame to shambles!  Raffle tickets will be called on Sunday.  The silent auction will conclude on Sunday at 2PM.
      </p>
      <PurchaseButton />
    </ContentPane>

    <ContentPane title="More Info" anchor="faq">
      <StyledList>
        <StyledListItem>Tickets</StyledListItem>
        <StyledList>
          <StyledListItem>Purchasing</StyledListItem>
          <StyledSubList>
            <StyledSubListItem>
In person at
              {' '}
              <a href="http://criticalhitgames.biz" target="_blank" rel="noreferrer noopener">Critical Hit Games</a>
            </StyledSubListItem>
            <StyledSubListItem>
              Online
              {' '}
              <a href="#topPurchase">here</a>
            </StyledSubListItem>
          </StyledSubList>
          <StyledListItem>Cost</StyledListItem>
          <StyledSubList>
            <StyledSubListItem>$30 for the whole weekend</StyledSubListItem>
            <StyledSubListItem>Includes two raffle tickets</StyledSubListItem>
          </StyledSubList>
        </StyledList>
        <StyledListItem>Cause</StyledListItem>
        <StyledList>
          <StyledListItem>Beneficiary</StyledListItem>
          <StyledSubList>
            <StyledSubListItem>All proceeds go towards purchasing games for the pediatric clinic</StyledSubListItem>
            <StyledSubListItem>Purchasing card and board games for low-income children and their families</StyledSubListItem>
          </StyledSubList>
          <StyledListItem>Other ways to give</StyledListItem>
          <StyledList>
            <StyledListItem>
While available: Admission Tickets (
              <a href="#topPurchase">see above</a>
)
            </StyledListItem>
            <StyledListItem>Raffle Tickets ($5/each)</StyledListItem>
            <StyledListItem>Silent Auction</StyledListItem>
            <StyledSubList>
              <StyledSubListItem>Ends on Sunday, January 25th at 2PM</StyledSubListItem>
            </StyledSubList>
          </StyledList>
        </StyledList>
        <StyledListItem>Event Dates and Details</StyledListItem>
        <StyledList>
          <StyledListItem>Friday, January 24th</StyledListItem>
          <StyledSubList>
            <StyledSubListItem>Time: 7PM to 12 AM</StyledSubListItem>
            <StyledSubListItem>
Location:
              {' '}
              <a href="https://www.brewerstastingroom.com/" target="_blank" rel="noreferrer noopener">Brewers Tasing Room</a>
              {' '}
@
              {' '}
              <a href="https://goo.gl/maps/ytkdf8hMt4uVYao58" target="_blank" rel="noreferrer noopener">11270 4th St N Suite 202, St. Petersburg, FL 33716</a>
            </StyledSubListItem>
          </StyledSubList>
          <StyledListItem>Saturday, January 25th</StyledListItem>
          <StyledSubList>
            <StyledSubListItem>Time: 12PM to 3AM</StyledSubListItem>
            <StyledSubListItem>
Location:
              {' '}
              <a href="http://criticalhitgames.biz" target="_blank" rel="noreferrer noopener">Critical Hit Games</a>
              {' '}
@
              {' '}
              <a href="https://g.page/Critical-Hit-Games-FL?share" target="_blank" rel="noreferrer noopener">11270 4th St N Suite 218, St. Petersburg, FL 33716</a>
            </StyledSubListItem>
          </StyledSubList>
          <StyledListItem>Sunday, January 26th</StyledListItem>
          <StyledSubList>
            <StyledSubListItem>Time: 12PM to 12AM</StyledSubListItem>
            <StyledSubListItem>
Location:
              {' '}
              <a href="http://criticalhitgames.biz" target="_blank" rel="noreferrer noopener">Critical Hit Games</a>
              {' '}
@
              {' '}
              <a href="https://g.page/Critical-Hit-Games-FL?share" target="_blank" rel="noreferrer noopener">11270 4th St N Suite 218, St. Petersburg, FL 33716</a>
            </StyledSubListItem>
          </StyledSubList>
        </StyledList>
      </StyledList>
    </ContentPane>

  </Layout>
);

export default IndexPage;

import React from 'react';
import PurchaseButton from './purchaseButton';

const PurchasePlea = () => (
  <div css={(theme) => ({
    textAlign: 'center',
    width: `${theme.center_width_percent}%`,
    margin: '0 auto',
  })}
  >
    <h3 css={{
      fontSize: '2em',
      margin: '20px 70px 50px 70px',
    }}
    >
      Play games to promote health & problem solving skills for local kids!
    </h3>
    <PurchaseButton />
  </div>
);

export default PurchasePlea;
